import { appWithTranslation } from 'next-i18next';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { Provider } from 'react-redux';
import { store, persistor } from 'src/redux/store';
import '../../public/static-file/scss/nprogress.scss';
import { ConfigCatProvider, LogLevel } from 'configcat-react';
import FeatureFlagBanner from 'src/components/Banner/FlagBanner';

import 'flatpickr/dist/themes/airbnb.css';
import 'react-phone-number-input/style.css';
import 'slick-carousel/slick/slick.css';
import middleware from 'src/middleware';
import '../../public/static-file/css/about.css';
import '../../public/static-file/css/aboutus.css';
import '../../public/static-file/css/article.css';
import '../../public/static-file/css/autocomplete-input.css';
import '../../public/static-file/css/birthday.css';
import '../../public/static-file/css/book-trial.css';
import '../../public/static-file/css/button.css';
import '../../public/static-file/css/contact.css';
import '../../public/static-file/css/custom.css';
import '../../public/static-file/css/franchise.css';
import '../../public/static-file/css/holiday-camp.css';
import '../../public/static-file/css/holidaypage.css';
import '../../public/static-file/css/homepage.css';
import '../../public/static-file/css/hover.css';
import '../../public/static-file/css/join-us.css';
import '../../public/static-file/css/modal.css';
import '../../public/static-file/css/onetraining.css';
import '../../public/static-file/css/pagination.css';
import '../../public/static-file/css/policies.css';
import '../../public/static-file/css/qna.css';
import '../../public/static-file/css/school-training.css';
import '../../public/static-file/css/slick-theme.css';
import '../../public/static-file/css/spinner.css';
import '../../public/static-file/css/style.css';
import '../../public/static-file/css/trainingservice.css';
import '../../public/static-file/css/weekly-training.css';
import '../../public/static-file/css/book-trial-2.css';
import '../../public/static-file/css/list-near-by-academy-lite.css';
import '../../public/static-file/css/find-a-class.css';
import '../../public/static-file/css/banner.css';

import PersistGateNext from 'src/components/PersistGateNext';

Router.events.on('routeChangeStart', (url) => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const delay = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });

export function reportWebVitals(metric) {
    metric.label === "web-vital" && console.log(metric);
}

function MyApp({ Component, pageProps }) {
    const configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY || '';


    return (
        <>
            <Provider store={store}>
                <ConfigCatProvider  sdkKey={configCatKey}>
                    <PersistGateNext persistor={persistor}>
                    <FeatureFlagBanner />
                    <Head>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1"
                        />
                    
                    </Head>
                    <Component {...pageProps} />
                    </PersistGateNext>
                </ConfigCatProvider>
            </Provider>
        </>
    );
}

MyApp.getInitialProps = async (appContext) => {
    const appProps = await App.getInitialProps(appContext);
    middleware(appContext);

    return { ...appProps };
};

export default appWithTranslation(MyApp);
