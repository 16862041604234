import { useFeatureFlag } from 'configcat-react';
import { useEffect, useState } from 'react';

import Banner from './Banner';

function FeatureFlagBanner() {
    const { value: isactiveValue, loading: isactiveLoading } = useFeatureFlag(
        'isactive',
        false,
    );
    const { value: bannertittleValue, loading: bannertittleLoading } =
        useFeatureFlag('bannertittle', 'Default');
    const { value: bannerbodyValue, loading: bannerbodyLoading } =
        useFeatureFlag('bannerbody', 'Default');

    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        const hideBannerCookie = getCookie('hideBanner');
        if (isactiveValue && !hideBannerCookie) {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    }, [isactiveValue]);

    const days = 2;

    const handleCloseBanner = () => {
        setShowBanner(false);
        setCookie('hideBanner', 'true', days);
    };

    if (isactiveLoading || bannertittleLoading || bannerbodyLoading) {
        return null;
    }

    return showBanner ? (
        <Banner
            title={bannertittleValue}
            body={bannerbodyValue}
            onClose={handleCloseBanner}
        />
    ) : null;
}

export default FeatureFlagBanner;

function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

function getCookie(name) {
    const cookieName = name + '=';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return '';
}
