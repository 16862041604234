import React from 'react';
import { PersistGate as PersistGateClient } from 'redux-persist/integration/react';

const PersistGateServer = ({ children }) => {
    return children;
}

const PersistGateNext = ({ children, ...restProps }) => {
    let runtime = process.env.RUNTIME;
    let PersistGate = PersistGateServer;
    
    if (runtime === 'browser') {
         PersistGate = PersistGateClient
    }

    return <PersistGate {...restProps}>
        {children}
    </PersistGate>;
}

export default PersistGateNext;