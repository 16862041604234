import { createStore, applyMiddleware } from 'redux';
import createMiddlewareSaga from 'redux-saga';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, PersistorOptions } from 'redux-persist';
import rootReducers from './reducers';
import rootSagas from './sagas';
import { isServer } from '../../isServer';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    timeout: 0,
    storage: storage,
    whitelist: ['headerReducer'],
    blacklist: [],
};

const sagaMiddleware = createMiddlewareSaga();
let middleware = applyMiddleware(sagaMiddleware);

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = createStore(persistedReducer, middleware);
const config = { middleware };

const persistor = persistStore(store, config, async () => {
    const stateData = store.getState();
});
sagaMiddleware.run(rootSagas);

export { store, persistor };
