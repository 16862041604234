import Constants from 'src/common/Constants';
import { headerActionType } from '../actions/actionTypes';

const initialState = {
    message: '',
    param: {},
    country: Constants.UK,
    academy: {},
};

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case headerActionType.CHANGE_LOCATION:
            return {
                ...state,
                type: headerActionType.CHANGE_LOCATION,
                data: action.data,
            };
        case headerActionType.CLOSE_LOCATION:
            return {
                ...state,
                type: headerActionType.CLOSE_LOCATION,
                param: action.param || {},
            };
        case headerActionType.CLOSE_MAP_POPUP:
            return { ...state, type: headerActionType.CLOSE_MAP_POPUP };
        case headerActionType.CHANGE_COUNTRY:
            return { ...state, country: action.country, type: action.type };
        case headerActionType.CHANGE_ACADEMY:
            return { ...state, academy: action.academy, type: action.type };
        case headerActionType.DELETE_ACADEMY:
            return { ...state, academy: action.academy, type: action.type };
        case headerActionType.OPEN_TRY_A_FREE:
            return {
                ...state,
                type: headerActionType.OPEN_TRY_A_FREE,
                data: action.data,
            };
        case headerActionType.CLOSE_TRY_A_FREE:
            return {
                ...state,
                type: headerActionType.CLOSE_TRY_A_FREE,
                param: action.param || {},
            };
        default:
            return state;
    }
};

export default headerReducer;
