import React from 'react';

const Banner = ({ title, body, onClose }) => {
    return (
        <div className="banner-feature-flag ">
            <div className="banner-feature-content">
                <h2 className="banner-feature-title">{title}</h2>
                <p className="banner-feature-body">{body}</p>
            </div>
            <button className="banner-feature-button" onClick={onClose}>
                Close
            </button>
        </div>
    );
};

export default Banner;
