import {
    CONTEN_SECURITY_POLICY,
    X_NONCE,
    nonce,
    sanitizedCSPHeader,
} from './common/csp-policies/csp.policies';
import { BASE_API, BASE_US_API } from './requests/ApiConfig';
import httpMethod from './services/httpMethod';

const middleware = (appContext) => {
    const { router, ctx } = appContext;

    const { res: Response } = ctx;

    Response.setHeader(CONTEN_SECURITY_POLICY, sanitizedCSPHeader);
    Response.setHeader(X_NONCE, nonce);
    Response.setHeader(
        'Cache-control',
        `s-maxage=${60 * 60 * 24 * 5}, stake-while-revalidate`,
    );

    httpMethod.changeBaseURL(
        router?.pathname?.includes('/us') ? BASE_US_API : BASE_API,
    );
};

export default middleware;
